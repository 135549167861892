<template>
  <section class="m-gift m-scroll" ref="scroll" @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <div class="search-bar">
        <form action="/" class="search-from">
            <img src="@/assets/icon_back@2x.png" alt="Back" class="backStep" @click="backStep">
            <van-search v-model="searckKey" placeholder="Search" autofocus clearable
            @search="onSearch" shape="round" background="transparent" style="flex: 1"></van-search>
            <router-link to="/en/cart" class="search-cart">
              <img src="@/assets/index/icon_shop_b@3x.png"  alt="cart" class="cart-img">
            </router-link>
            <m-dot :number="cartNum" :animate="dotAnimate" ref="dot"></m-dot>
        </form>
    </div>
    <div class="header-bg"></div>

    <div class="classify">
      <div class="items" v-for="(item, index) in typeList" :key="index"
      @click="$router.push({path: '/en/sublist', query: {id: item.id, title: item.title_en, num: item.row_pro_num, cid: $route.query.id}})">
        <img class="img" :src="item.pic_url" />
        <div class="title">
          <p>{{item.title_en}}</p>
          <p>{{item.num}} items</p>
        </div>
      </div>
    </div>

    <!-- 热销单品 -->
    <index-cell :title="activeType.name" value="Show All" :to="`/en/sublist?id=${activeType.id}&title=${activeType.name}&num=${activeType.num}&cid=${$route.query.id}`" class="gift-cell" />
    <goods-columns :data="activeTypeList" :columns="1" @cartClick="handleCart"></goods-columns>
  
    <!-- 为您推荐 -->
    <m-divider title="Recommended" style="margin:0 auto 10px auto;"></m-divider>
    <goods-columns @cartClick="handleCart" :data="remGoodsList"></goods-columns>

    <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>
    <p class="search-no" v-if='noMore'>No More Products</p>

    <!-- 选择SKU 加入购物车 -->
    <m-sku title="Add to Cart" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>
  </section>
</template>

<script>

import MDot         from '@/components/en/m-dot.vue'
import MSku         from '@/components/en/m-sku.vue'
import IndexCell    from '@/components/en/index-cell.vue'
import GoodsColumns from '@/components/en/goods-columns.vue'
import MDivider     from '@/components/en/m-divider.vue'
import MAnimate     from '@/components/en/cart-animate.vue'
import CartFlex     from '@/components/en/cart-flex.vue'

import { getTypeList, getActiveType, getRemGoodsList } from '@/api/en/gift.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
  mixins: [ MixinScroll ],
  name:'Index',
  components:{ MDot, IndexCell, GoodsColumns, MDivider, MSku, MAnimate, CartFlex },
  data(){
    return {
      searckKey: '',
      offsetTop:0,
      tab:0,          // tabs选中的面板
      loading:false,
      skuModal:false,
      start:false,
      start_dom:null,
      tabTop:44,
      dotAnimate:false,
      cartNum:0,
      scrollTop:false,
      typeList: [],
      activeType: {},
      activeTypeList: [],
      remGoodsList: [],
      page: 1,
      islock: false,
      noMore: false,
      goodsId: ''
    }
  },

  mounted(){
    let clientWidth = document.body.clientWidth
    this.tabTop = 44/375*clientWidth
  },

  methods:{

    // 内容滑动事件
    handleScroll(e){
      e.preventDefault()
      e.stopPropagation()
      this.offsetTop = e.target.scrollTop

      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock && !this.noMore){
          this.loading = true
          this.page++
          this.getRemGoodsListHandle()
        }
      }
    },
    backStep() {
        // 返回上一步
        this.$router.back()
    },
    onSearch() {
      // 前往搜索页面
      this.$router.push(`/en/search?s=gift&key=${this.searckKey}`)
    },
    onCancel() {
        // 点击取消
        let back = localStorage.getItem('back_path')
        if(!back) {
            back = '/'
        }

        this.$router.push({path: back})
    },

    // 切换 tab
    handleTab(tab){
      let left = 6.56667 + tab  * 21.66667
      this.$refs.tabActive.style.left = left + 'vw'
      this.tab = tab
    },
    // 点击筛选 tab
    handleFilter(){
      this.filter = true
    },
    // 点击月份筛选
    handleMonth(month){
      this.month = month
      let timer = setTimeout( () => {
        this.filter = false
        clearTimeout(timer)
      }, 300)
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        this.dotAnimate = true 
        this.cartNum ++

        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)

        clearTimeout(timer)
      },300)
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },
    getTypeListHandle() {
      getTypeList(this.$route.query.id).then(res => {
        if(res) {
          this.typeList = res.data.filter(item => {
            return item.status == 1
          })
          this.typeList.forEach(el => {
            if(el.tagging == 1) {
              this.activeType = {
                name: el.title_en,
                id: el.id,
                num: el.row_pro_num
              }
            }
          })
        }
      })
    },
    getActiveTypeHandle() {
      getActiveType().then(res => {
        if(res) {
          this.activeTypeList = res.data.data
        }
      })
    },
    getRemGoodsListHandle() {
      this.islock = true
      getRemGoodsList('gift',{page: this.page}).then(res => {
        if(res) {
          this.remGoodsList = this.remGoodsList.concat(res.data.data)
        } else {
          this.noMore = true
        }
      }).finally(() => {
        this.loading = false
        this.islock = false
      })
    }
  },
  created() {
    this.getTypeListHandle()
    this.getActiveTypeHandle()
    this.getRemGoodsListHandle()
  }
}
</script>

<style lang="less" scoped>
.cart-img {
  width:22px;
  height:22px;
}
.search-no{line-height:80px;text-align:center;color:#888;font-size:14px}
@import './gift.less';
</style>